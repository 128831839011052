import React from "react"
import styled from 'styled-components'

const Grid = ({ children, className }: GridProps): React.ReactElement => (
  <Wrapper {...{ className }} >
    {children}
  </Wrapper>
)


const Wrapper = styled.div`
  position: relative;
  display: grid;
  padding: 10px;
  grid-template-columns: repeat(1, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 20px;

  @media only screen and (min-width: 768px) {
    padding: 25px;
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: 25px;
    grid-row-gap: 25px;
  }
`

type GridProps = {
  children: React.ReactNode
  className?: string
}

export default Grid