import React from "react"
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import { Helmet } from 'react-helmet'
import { useLocation } from '@reach/router'
import { Footer } from '@components/organisms'
import { SiteSettings } from '@types'
import FontFaceObserver from 'fontfaceobserver'


import "../assets/stylesheets/reset.min.css"
import "../assets/stylesheets/main.scss"


const Layout = ({ className, children }: LayoutProps): React.ReactNode => {

  const fontObserver = new FontFaceObserver('Grot10')
  Promise.all([fontObserver.load()]).then(
    () => document.documentElement.classList.remove('fonts-loading'),
    () => console.log('Font is not available')
  )

  const data = useStaticQuery(graphql`
    query LayoutQuery {
      sanitySiteSettings {
        ...siteSettings
      }
    }
  `)
  
  const loaded = true

  const { sanitySiteSettings: { title, description, keywords, shortcutIcon, touchIcon, shareImage } } = data

  const meta = [
    { property: "og:type", content: "website" },
    { property: "og:url", content: `${process.env.GATSBY_SITE_BASE_URL}` },
    { charSet: 'UTF-8' },
    { name: "viewport", content: "width=device-width, user-scalable=no, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0" },
    { httpEquiv: "X-UA-Compatible", content: "ie=edge" },
    { name: "apple-mobile-web-app-capable", content: "yes" },

    { property: "og:title", content: title },
    { name: "twitter:title", content: title },
    { name: "twitter:card", content: "summary_large_image" },
    { name: "twitter:site", content: "elanaschlenker" },
    
    { name: "description", content: description },
    { name: "twitter:description", content: description },
    { property: "og:description", content: description },

    { name: "keywords", content: keywords },
    
    { property: "og:image", content: `${shareImage?.asset?.url}?w=640&h=640&fm=jpg&q=75` },
    { name: "twitter:image", content: shareImage?.asset?.url },
  ]
  
  const { pathname } = useLocation()
  const isVault = pathname.includes('vault')

  return (
    <>
      <Helmet {...{ meta, title }} >
        <link rel="apple-touch-icon" href={touchIcon?.asset.url} />
        <link rel="icon" type="image/png" href={shortcutIcon?.asset.url} sizes="32x32" />
      </Helmet>
      <Top id="top" />
      <LayoutContentWrapper className={loaded ? 'loaded' : 'loading'} {...{ className, isVault }}>
        {children}
        <Footer />
      </LayoutContentWrapper>
    </>
  )
}


const LayoutContentWrapper = styled.div<{ isVault?: boolean }>`
  background-color: ${props => props.isVault ? `rgb(204, 255, 0)` : 'white'};
  transition: background-color 0.35s ease-in-out;
  min-height: 100vh;
  padding-bottom: 10px;
  @media only screen and (min-width: 768px) {
    padding-bottom: 25px;
  }
`


const Top = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 1px;
`



interface LayoutProps extends SiteSettings {
  subtitle?: string
  preview?: boolean
  hasHero?: boolean
  className?: string
  children: React.ReactNode
}


export default Layout
