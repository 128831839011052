import React from 'react'
import styled from 'styled-components'
import { Link } from '@components/atoms'
import { Navigation } from '@components/organisms'
import { MenuButton } from '@components/organisms/Navigation'


const Header = ({ sticky }: { sticky?: boolean }) => {
  const [active, setActive] = React.useState(false)
  return (
    <>
      <Wrapper {...{ sticky }} >
        <Items>
          <div>
            <Link to="/" >
              <StyledImg src="/logo-menu.png" />
            </Link>
          </div>
          <MenuButton onClick={() => setActive(true)} >menu</MenuButton>
        </Items>
      </Wrapper>
      <Navigation {...{ active, setActive }} />
    </>
  );
}


const Wrapper = styled.header<{
  sticky?: boolean,
}>`
  position: ${props => props.sticky ? 'sticky' : 'fixed'};
  z-index: 99;
  top: 0px;
  width: 100%;
  padding: 25px;
  box-sizing: border-box;
  pointer-events: none;
`

const Items = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  pointer-events: all;
`

const StyledImg = styled.img`
  height: 30px;
  @media only screen and (min-width: 768px) {
    height: 2.5vw;
  }
  margin-top: -8px;
  width: auto;
`


export default Header;