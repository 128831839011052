import * as React from "react"
import styled from 'styled-components'
import { Link } from '@components/atoms'


const CapsuleButton = styled(props => <Link {...props} />)`
  display: inline-block;
  padding: 2px 10px 4px 10px;
  background-color: rgba(204, 255, 0, 1);
  color: black;
  border-radius: 15px;
  text-transform: uppercase;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;
  &:hover {
    color: rgba(204, 255, 0, 1);
    background-color: black;
  }
`

export default CapsuleButton