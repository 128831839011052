import React, { useEffect, useRef, useState } from 'react'
import { useGLTF } from '@react-three/drei'
import { useThree, useFrame } from "@react-three/fiber"
import {
  WebGLCubeRenderTarget,
  RGBAFormat,
  LinearMipmapLinearFilter,
  Euler,
  Quaternion,
} from "three"
import { isClient, isTouchDevice, coordinatesFromSanity, useWindowSize } from '@utils'
import { throttle } from 'lodash'
import { Coordinate } from '@types'


const Info = ({ modelPosition, modelPositionMobile}: { modelPosition: Coordinate, modelPositionMobile: Coordinate} ) => {
  const windowSize = useWindowSize()
  const mobile = (windowSize.x < 768)
  const mesh = useRef<THREE.Group>()
  const isTouch = isTouchDevice()

  // @ts-ignore
  const { nodes } = useGLTF('/info.glb')
  const { scene, gl } = useThree()

  const cubeCamera = React.useRef<THREE.CubeCamera>()

  const [renderTarget] = React.useState(new WebGLCubeRenderTarget(256, {
    format: RGBAFormat,
    generateMipmaps: true,
    minFilter: LinearMipmapLinearFilter,
  }))

  const rotationEuler = React.useMemo(() => new Euler(0, 0, 0), [])
  const rotationQuaternion = React.useMemo(() => new Quaternion(0, 0, 0, 0), [])

  useEffect(() => {
    if( !isClient ) return
    const updateMousePosition = throttle((e: MouseEvent) => {
      if( isTouch && windowSize.x <= 1024 ) {
        return
      }
      rotationEuler.set(
        (e.clientY / windowSize.y) - 0.5,
        (e.clientX / windowSize.x) - 0.5,
        0
      )
      rotationQuaternion.setFromEuler(rotationEuler)
      mesh.current?.quaternion.slerp(rotationQuaternion, 0.1)
    }, 5)
    window.addEventListener("mousemove", updateMousePosition)
    return () => window.removeEventListener("mousemove", updateMousePosition)
  }, [mesh, rotationQuaternion, rotationEuler, windowSize])

  useFrame(({ clock }) => {
    if(!mesh || !mesh.current || !cubeCamera.current) return
    cubeCamera.current.update(gl, scene)
    // ^ https://tympanus.net/codrops/2020/09/30/creating-mirrors-in-react-three-fiber-and-three-js/
    if( isTouch && windowSize.x <= 1024 ) {
      rotationEuler.set( 0, clock.getElapsedTime(), 0 )
      rotationQuaternion.setFromEuler(rotationEuler)
      mesh.current.quaternion.slerp(rotationQuaternion, 0.1)
    }
  })
  
  const position = coordinatesFromSanity(mobile && modelPositionMobile ? modelPositionMobile : modelPosition ? modelPosition : { x: 0, y: -70, z: -900})
  const scale = (mobile && modelPositionMobile) ? modelPositionMobile.s : mobile ? 0.6 : modelPosition ? modelPosition.s : 0.75

  return (
    <>
      <cubeCamera 
        name="cubeCamera" 
        ref={cubeCamera} 
        args={[1, 1, renderTarget]} 
      />
      <group 
        ref={mesh}
        dispose={null}
        position={position} 
        scale={scale}
      >
        <mesh geometry={nodes.mesh_0.geometry} >
          <meshBasicMaterial envMap={cubeCamera.current?.renderTarget.texture} />
        </mesh>
      </group>
    </>
  )
}

useGLTF.preload('/info.glb')


export default Info