import React from "react"
import { GridItemCaption, GridItemImage, GridItemVideo, } from '@components/grid'
import { GridItemProps, GridItemCaptionProps, GridItemVideoProps, GridItemImageProps, } from '@types'


const GridItem = (
  props: GridItemProps
): React.ReactElement => {
  if( props._type === 'gridItem' && props.gridItemTypeSwitch?.gridItemType === 'caption' ) {
    return <GridItemCaption {...props as GridItemCaptionProps} />
  }
  if( props._type === 'gridItem' && props.gridItemTypeSwitch?.gridItemType === 'image' ) {
    return <GridItemImage {...props as GridItemImageProps} />
  }
  if( props._type === 'gridItem' && props.gridItemTypeSwitch?.gridItemType === 'video' ) {
    return <GridItemVideo {...props as GridItemVideoProps} />
  }
  return <></>
}


export default GridItem