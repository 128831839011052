import React from "react"

const WithData = (mapDataToProps: any, Component: React.FunctionComponent<any>) => {
  return (props: any): React.ReactElement => {
    const newProps = Object.assign({}, props, mapDataToProps(props.data))
    return <Component {...newProps} />
  }
}

export default WithData
