import { useThree } from '@react-three/fiber'
import { useCubeTexture } from '@react-three/drei'
import { sRGBEncoding } from 'three'
import { ImageProps } from '@types'


const imgixPath = (input: string) => input.replace('cdn.sanity.io/images/pbavrd7u/production', '4gm-img.imgix.net')


// note to self: 6th element is the back of the cube
const SkyBox = ({ reflectionImage }: { reflectionImage: ImageProps }) => {
  const img = imgixPath(reflectionImage.asset.url)
  const { scene } = useThree()
  const envMap = useCubeTexture([
    img,
    img,
    img,
    img,
    img,
    img,
  ], { path: '' })  
  envMap.encoding = sRGBEncoding
  
  scene.background = envMap    
  return null
}

export default SkyBox


{/* useCubeTexture.preload([
  "fgm_sq3.jpg",
  "fgm_sq3.jpg",
  "fgm_sq3.jpg",
  "fgm_sq3.jpg",
  "fgm_sq3.jpg",
  "fgm_sq3.jpg",
], { path: '/' }) */}
