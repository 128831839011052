import React from "react"
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'
import { Link } from '@components/atoms'
import { palette } from '@theme'
import { useLocation } from '@reach/router'
import { NavigationLink } from '@types'


interface NavigationProps {
  active: boolean
  setActive: (x: boolean) => void
}


const Navigation = ({ active, setActive }: NavigationProps) => {
  const { pathname } = useLocation()
  React.useEffect(() => setActive(false), [pathname])

  const { sanitySiteSettings: { navigationLinks } } = useStaticQuery(graphql`
    query NavigationQuery {
      sanitySiteSettings {
        ...siteSettings
      }
    }
  `)
  
  return (
    <>
      <Wrapper {...{ active }} >
        <CloseButton onClick={() => setActive(false)} >X</CloseButton>
        <ul>
          {navigationLinks.map((link: NavigationLink, i: number) => (
            <li key={`navLink_${i}`} ><StyledLink to={link.url} >{link.label}</StyledLink></li>
          ))}
        </ul>
      </Wrapper>
      <Onionskin {...{ active }} onClick={() => setActive(false)}  />
    </>
  )
}


const Wrapper = styled.nav<Pick<NavigationProps, 'active'>>`
  position: fixed;
  z-index: 101;
  top: 0;
  right: 0;
  box-sizing: border-box;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media only screen and (min-width: 768px) {
    width: 50%;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 25px 15px;
  }

  background-color: ${palette.electricLime};
  color: ${palette.black};
  
  visibility: hidden;
  touch-events: none;
  pointer-events: none;
  opacity: 0;
  transform: translateX(50px);
  transition: 
    visibility 0.01s 0.35s, 
    opacity 0.3s 0s ease-in-out, 
    transform 0.3s 0s ease-in-out;
  will-change: opacity, transform;
  ${props => props.active && `
    visibility: visible;
    transform: translateX(0px);
    opacity: 1;
    transition: 
      visibility 0.01s, 
      opacity 0.3s 0.1s ease-in-out, 
      transform 0.3s 0.1s ease-in-out;
    li {
      touch-events: all;
      pointer-events: all;
    }
  `}
  
  @media only screen and (max-width: 767px) {
    ul {
      width: 400px;
      text-align: center;
    }
  }
`

const CloseButton = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 25px 25px;
  cursor: url('/cursors/pointer.cur') 6 0, auto;
  cursor: -webkit-image-set(
    url('/cursors/pointer.cur') 1x,
    url('/cursors/pointer_hi.cur') 2x
  ) 6 0, auto;
`

const StyledLink = styled(props => <Link {...props} />)`
  display: block;
  width: 100%;
  margin: 0.75em 0;
  border-radius: 10px;
  line-height: 1.1em;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;
  color: ${palette.black};
  @media only screen and (min-width: 768px) {
    margin: 0.125em 0;
    padding: 0 10px;
  }
  &:hover {
    color: ${palette.electricLime};
    background-color: ${palette.black};
  }
`

const Onionskin = styled.div<Pick<NavigationProps, 'active'>>`
  display: ${props => props.active ? 'block' : 'none'};
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  height: 100vh;
  width: 100%;
`


export const MenuButton = styled.div`
  cursor: url('/cursors/pointer.cur') 6 0, auto;
  cursor: -webkit-image-set(
    url('/cursors/pointer.cur') 1x,
    url('/cursors/pointer_hi.cur') 2x
  ) 6 0, auto;
  text-transform: uppercase;
  pointer-events: all;
`


export default Navigation