import React from "react"
import styled from 'styled-components'
import { BlockRenderer, Picture, LinkMask } from '@components/atoms'
import { GridItemWrapper } from '@components/grid'
import { GridItemImageProps } from '@types'
import { useGridHelper } from '@utils'


const GridItemImage = ({
  link,
  post,
  children,
  description,
  gridItemTypeSwitch,
  className,
  columns, 
  columnStart,
  frameSize,
  paddedBackgroundColor,
  square,
}: { className?: string } & GridItemImageProps): React.ReactElement => {
  const { gridHelperEnabled } = useGridHelper()
  if( !gridItemTypeSwitch.gridItemImage ) return <></>
  const {
    gridItemImage: {
      image,
      _rawCaption,
      additionalPadding,
    },
  } = gridItemTypeSwitch

  return (
    <GridItemWrapper {...{ className, columns, columnStart, gridHelperEnabled, square }} >
      <LinkMask {...{ link, post }} />
      <PictureWrapper {...{ columns, paddedBackgroundColor, frameSize, square }} className="paddingHighlight" >
        <Picture 
          src={image?.asset.url}
          dimensions={image?.asset.metadata.dimensions}
          alt={description}
          columns={columns || 6}
          {...{ additionalPadding }}
        />
      </PictureWrapper>
      {_rawCaption && (
        <CaptionWrapper>
          <BlockRenderer>{_rawCaption}</BlockRenderer>
          {children}
        </CaptionWrapper>
      )}
    </GridItemWrapper>
  )
}

// todo: just make these stupid wrappers square. I'm so dumb.
export const PictureWrapper = styled.div<{ 
  columns?: number, 
  frameSize?: number,
  square?: boolean,
  paddedBackgroundColor?: { hex: string },
}>`
  line-height: 0;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  ${props => props.paddedBackgroundColor && `
    background-color: ${props.paddedBackgroundColor.hex};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 20px;
    border-radius: 15px;
    @media only screen and (min-width: 768px) {
      border-radius: 10px;
      padding: 50px;
    }
    @media only screen and (min-width: 1024px) {
      padding: 80px;
    }
    img, video {
      width: 100%;
      border-radius: 0;
    }
  `}
  @media only screen and (min-width: 768px) {
    ${props => props.square && `
      height: calc(${props.columns} * ((100vw - 175px) / 6) + (${props.columns} * 25px) - 25px);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: transparent !important;
      img, video {
        flex: 1;
        max-height: calc(${props.columns} * ((100vw - 175px) / 6) + (${props.columns} * 25px) - 25px);
        max-width: calc(${props.columns} * ((100vw - 175px) / 6) + (${props.columns} * 25px) - 25px);
        width: auto;
        height: 100%;
      }
    `}
  }
`


export const CaptionWrapper = styled.div`
  padding: 20px 20px 0px 20px;
  @media only screen and (min-width: 768px) {
    padding: 20px 70px 70px 70px;
  }
  margin-bottom: 1em;
  strong {
    color: black;
  }
`


export default GridItemImage