/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/
import React from 'react'
import { useTexture } from '@react-three/drei'
import { sRGBEncoding } from 'three'
import { ImageProps } from '@types'
import { useWindowSize } from '@utils'

interface FauxSkyProps {
  mastheadImage: ImageProps
  mobileMastheadImage?: ImageProps
}


const imgixPath = (input: string) => input.replace('cdn.sanity.io/images/pbavrd7u/production', '4gm-img.imgix.net')


const FauxSky = ({ mastheadImage, mobileMastheadImage }: FauxSkyProps) => {
  const windowSize = useWindowSize()
  const mobile = (windowSize.x < 768)

  const bitmapMaterial = useTexture(imgixPath(mobile && mobileMastheadImage ? mobileMastheadImage.asset.url : mastheadImage.asset.url))
  bitmapMaterial.encoding = sRGBEncoding
  
  return (
    <mesh /* front */
      position={[0, 0, -1990]} 
      rotation={[0, 0, 0]} 
      visible 
      scale={(mobile && mobileMastheadImage) ? 90 : (mobile) ? 140 : 150}
    >
      <planeBufferGeometry args={[22, 22, 1, 1]} />
      <meshBasicMaterial 
        toneMapped={false}
        attach="material"
        map={bitmapMaterial}
      />
    </mesh>
  )
}



export default FauxSky