import React from 'react'
import GridHelperSwitch from './GridHelperSwitch'

export type GridHelperActionType = 'toggle'

interface GridHelperAction {
  type: GridHelperActionType
  payload?: {}
}

type Dispatch = (action: GridHelperAction) => void
interface State {
  gridHelperEnabled: boolean
}
type GridHelperProviderProps = {
  children: React.ReactNode
}

const initialState: State = {
  gridHelperEnabled: false,
}
const GridHelperContext = React.createContext<State | undefined>(initialState)
const GridHelperDispatchContext = React.createContext<Dispatch | undefined>(undefined)

function gridHelperReducer(state: State, action: GridHelperAction): State {
  switch (action.type) {
    case 'toggle': {
      return {...state, gridHelperEnabled: !state.gridHelperEnabled }
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

function GridHelperProvider({children}: GridHelperProviderProps) {
  const [state, dispatch] = React.useReducer(gridHelperReducer, initialState)
  return (
    <GridHelperContext.Provider value={state}>
      <GridHelperDispatchContext.Provider value={dispatch}>
        {children}
      </GridHelperDispatchContext.Provider>
    </GridHelperContext.Provider>
  )
}

function useGridHelper() {
  const context = React.useContext(GridHelperContext)
  if (context === undefined) {
    throw new Error('useGridHelper must be used within a GridHelperProvider')
  }
  return context
}

function useGridHelperDispatch() {
  const context = React.useContext(GridHelperDispatchContext)
  if (context === undefined) {
    throw new Error('useGridHelperDispatch must be used within a GridHelperProvider')
  }
  return context
}

export { GridHelperProvider, useGridHelper, useGridHelperDispatch, GridHelperSwitch }