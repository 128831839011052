import React, { useState, useEffect, Suspense } from 'react'
import styled from 'styled-components'
import { Canvas } from "@react-three/fiber"
import { PerspectiveCamera, AdaptiveDpr,  } from '@react-three/drei'
import { SkyBox, EmptyBox, FauxSky } from '@components/threedee'
import { ImageProps } from '@types'
import { useWindowSize } from '@utils'


interface MastheadProps {
  mastheadImage: ImageProps
  mobileMastheadImage?: ImageProps
  reflectionImage: ImageProps
  short?: boolean
  children: React.ReactElement
}

const Masthead = ({ reflectionImage, mastheadImage, mobileMastheadImage, short, children }: MastheadProps) => {
  const { x } = useWindowSize()
  const desktopHeight = short ? '40vw' : '56.25vw'
  const mobileHeight = short ? '65vw' : '100vw'
  const [heightOfCanvas, setHeightOfCanvas] = useState<string>(desktopHeight)  
  useEffect(() => setHeightOfCanvas((x < 768) ? mobileHeight : desktopHeight), [x])
  
  const fov = short ? 30 : 50
  
  return (
    <Wrapper
      {...{ desktopHeight, mobileHeight }}
    >
      {typeof window !== 'undefined' && (
        <Canvas
          dpr={1}
          className="canvas" 
          style={{height: heightOfCanvas}}
          mode="concurrent"
          {...{ fov }}
        >
          <PerspectiveCamera makeDefault {...{ fov }} aspect={.5625} near={0.1} far={2000} />
          <AdaptiveDpr pixelated />
          <Suspense fallback={<EmptyBox />} > 
            <FauxSky {...{ mastheadImage, mobileMastheadImage }} />
            <SkyBox {...{ reflectionImage }} />
            {children}
          </Suspense>
        </Canvas>
      )}
    </Wrapper>
  )
}


const Wrapper = styled.header<{
  loadingSrc?: string
  loadingSrcMobile?: string
  mobileHeight: string
  desktopHeight: string
}>`
  position: relative;
  width: 100%;
  height: ${props => props.mobileHeight};
  @media only screen and (min-width: 768px) {
    height: ${props => props.desktopHeight};
  }
  canvas {
    width: 100%;
    height: 100%;
  }
`

export default Masthead