import styled from 'styled-components'


interface GridItemWrapperProps {
  fullBleed?: boolean,
  columns?: number, 
  columnStart?: number,
  paddedBackgroundColor?: { hex: string }
  gridHelperEnabled?: boolean,
}


const GridItemWrapper = styled.div<GridItemWrapperProps>`
  box-sizing: border-box;
  position: relative;
  grid-column-start: 1;
  grid-column-end: span 1;
  overflow: hidden;

  @media only screen and (min-width: 768px) {
    grid-column-start: ${props => props.columnStart || 'auto'};
    grid-column-end: span ${props => props.columns || 6};
  }
  
  align-self: start;

  ${props => props.gridHelperEnabled && `
    outline: 1px dashed red;
  `}
  
`


export default GridItemWrapper