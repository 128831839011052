import React from 'react'
import styled from 'styled-components'
import { useInView } from 'react-intersection-observer'


// TODO import { imgixPath } from '@utils'
const imgixPath = (input: string) => input.replace('cdn.sanity.io/images/pbavrd7u/production', '4gm-img.imgix.net')


const Picture = ({
  objectFit = 'contain',
  className = '',
  src,
  dimensions,
  alt,
  columns,
  children,
  additionalPadding,
  ...props
}: PictureProps): React.ReactElement => {
  if(!src) return <></>
  // Set CDN src. 
  const cdnSrc = imgixPath(src)

  const { ref, inView } = useInView({
    threshold: .15,
    triggerOnce: true
  })
  
  const [visible, setVisible] = React.useState(false)
  React.useEffect(() => {
    if(visible) return
    if(inView) setVisible(true)
  }, [inView])
  
  // approximate width-of-layout cut by column width
  const desktopCut = (w: number) => !columns ? w : Math.floor(w * (columns / 6))

  const $additionalPadding = !additionalPadding
    ? undefined
    : additionalPadding + `vw 0`

  return (
      <picture>
        {!!children && children}
        {!children && (
          <>
            <source type="image/webp" media="(max-width: 767px)"  srcSet={`${cdnSrc}?fm=webp&w=840`} />
            <source type="image/webp" media="(min-width: 1600px)" srcSet={`${cdnSrc}?fm=webp&w=${desktopCut(2000)}, ${cdnSrc}?fm=webp&w=${desktopCut(4000)} 2x`} />
            <source type="image/webp" media="(min-width: 1280px)" srcSet={`${cdnSrc}?fm=webp&w=${desktopCut(1600)}, ${cdnSrc}?fm=webp&w=${desktopCut(3200)} 2x`} />
            <source type="image/webp" media="(min-width: 1024px)" srcSet={`${cdnSrc}?fm=webp&w=${desktopCut(1280)}, ${cdnSrc}?fm=webp&w=${desktopCut(2560)} 2x`} />
            <source type="image/webp" media="(min-width: 768px)"  srcSet={`${cdnSrc}?fm=webp&w=${desktopCut(1024)},  ${cdnSrc}?fm=webp&w=${desktopCut(1800)} 2x`} />
          </>
        )}

        <StyledImage
          alt={alt}
          {...{ objectFit, visible, ref, $additionalPadding }}
          {...dimensions}
          className={`loadable ${className}`}
          {...props}
        />
      </picture>
  )
}



// Styling


interface StyledImageProps {
  visible: boolean
  objectFit: 'contain' | 'cover'
  aspectRatio: number
  $additionalPadding?: string
}

const StyledImage = styled.img<StyledImageProps>`
  display: block;
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0;
  border: 0;
  object-fit: ${props => props.objectFit};
  border-radius: 15px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  @media only screen and (min-width: 768px) {
    padding: ${props => props.$additionalPadding ? props.$additionalPadding : 0};
    border-radius: 10px;
  }
`



// Typing

type PictureProps = {
  src: string
  objectFit?: 'contain' | 'cover'
  dimensions: {
    width: number
    height: number
    aspectRatio: number
  }
  className?: string
  alt?: string
  columns?: number
  additionalPadding?: string
  children?: React.ReactNode
}



export default Picture
