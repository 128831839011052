import React from "react"
import styled from 'styled-components'
import { useStaticQuery, graphql } from "gatsby"
import { Picture, BlockRenderer } from '@components/atoms'
import { Grid } from '@components/grid'
import { palette } from '@theme'
import { GridHelperSwitch } from '@utils'


interface SocialLink {
  _key: string
  networkName: string
  link: string
}


const Footer = (props: any) => {
  const data = useStaticQuery(graphql`
    query Footer {
      sanitySiteSettings {
        socialLinks {
          _key
          networkName
          link
        }
        contactEmail
        _rawAddress
      }
    }
  `)
  if(!data) return <></>
  const { sanitySiteSettings: { socialLinks, contactEmail, _rawAddress } } = data
  const currentTime = new Date()
  return (
    <Wrapper {...props} >
      <StyledGrid>
        
        <Block>
          <Picture src="/fgm_static.jpg" dimensions={{ width: 936, height: 358, aspectRatio: 0.3824 }} />
        </Block>
        
        {contactEmail && (
          <Block>
            <h2>Contact</h2>
            <p>
              <a href={`mailto:${contactEmail}`}>{contactEmail}</a>
            </p>
          </Block>
        )}
        
        {socialLinks && (
          <Block>
            <Social>
              {socialLinks.map((link: SocialLink) => (
                <li key={link._key} ><a target="_blank" href={link.link} >{link.networkName}</a></li>
              ))}
            </Social>
          </Block>
        )}
        
        {_rawAddress && (
          <Block>
            <h2>Visit</h2>
            <BlockRenderer>{_rawAddress}</BlockRenderer>
          </Block>
        )}
        
        <Copyright>&copy;{currentTime.getFullYear()} All Rights Reserved For Good Measure, LLC</Copyright>  
        {process.env.GATSBY_SANITY_WATCH_MODE && (<GridHelperSwitch />)}
      </StyledGrid>
    </Wrapper>
  )
}


const Wrapper = styled.footer`
  background: black;
  color: white;
  margin: 10px 10px 0 10px;
  padding: 15px;
  border-radius: 15px;
  @media only screen and (min-width: 768px) {
    border-radius: 10px;
    padding: 40px 72px;
    margin: 25px 25px 0 25px;
  }
  a {
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;
    border-radius: 10px;
    margin-left: -10px;
    padding: 0 10px;
    &:hover {
      background-color: ${palette.electricLime};
      color: ${palette.black};
    }
  }
`


const StyledGrid = styled(props => <Grid {...props} />)`
  grid-row-gap: 72px;
`


const Block = styled.div`
  grid-column-end: span 1;
  text-align: center;
  @media only screen and (min-width: 768px) {
    text-align: left;
    grid-column-end: span 3;
  }
  color: ${palette.gray};
  h2 {
    color: ${palette.white};
    text-transform: uppercase;
  }
  @media only screen and (min-width: 768px) {
    font-size: 3vw;
  }
  @media only screen and (min-width: 1400px) {
    font-size: 50px;
  }
  img {
    @media only screen and (max-width: 767px) {
      margin: auto;
    }
    max-width: 75%;
  }
`

const Copyright = styled.p`
  @media only screen and (min-width: 768px) {
    grid-column-end: span 6;    
  }
  text-align: center;
  color: ${palette.gray};
`

const Social = styled.ul`
  li {
    text-transform: uppercase;
    a {
      color: ${palette.white};
      &:after {
        content: ' ↗';
        
      }
    }
  }
`

export default Footer