import React from "react"
import styled from 'styled-components'
import { Link } from '@components/atoms'
interface LinkMaskProps {
  post?: {
    slug: {
      current: string
    }
  }
  link?: string
}


const LinkMask = ({ post, link }: LinkMaskProps) => {
  if(link) {
    return <AnchorWrapper href={link} />
  }
  if(post) {
    return <LinkWrapper to={`/${post.slug.current}`} />
  }
  return <></>
}


const LinkMaskStyle = `
  display: block;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent !important;

  & ~ .paddingHighlight {
    display: inline-block;
    transition: background-color 0.25s ease-in-out;    
  }
  & ~ div div h1,
  & ~ div div h2 {
    display: inline-block;
    border-radius: 10px;
    padding: 0 10px;
    margin-left: -10px;
    transition: background-color 0.25s ease-in-out;    
  }
  &:hover ~ .paddingHighlight, &:hover ~ div div h1,
  &:hover ~ .paddingHighlight, &:hover ~ div div h2 {
    background-color: rgba(204, 255, 0, 1);
  }
  ~ div div .hoverTextColor {
    transition: color 0.15s ease-in-out;
  }
  cursor: url('/cursors/pointer.cur') 6 0, auto;
  cursor: -webkit-image-set(
    url('/cursors/pointer.cur') 1x,
    url('/cursors/pointer_hi.cur') 2x
  ) 6 0, auto;
`

const AnchorWrapper = styled.a`
  ${LinkMaskStyle}
`


const LinkWrapper = styled(props => <Link {...props} />)`
  ${LinkMaskStyle}
`


export default LinkMask