import React from "react"
import styled from 'styled-components'
import { TransitionState } from "gatsby-plugin-transition-link"
import { graphql, useStaticQuery } from 'gatsby'


type TransitionStatus = 'entering' | 'entered' | 'exiting' | 'exited'


interface TransitionMaskProps {
  transitionStatus: TransitionStatus
  loadingSrc?: string
  loadingSrcMobile?: string
}


const imgixPath = (input: string) => input.replace('cdn.sanity.io/images/pbavrd7u/production', '4gm-img.imgix.net')


const TransitionMask = ({ transitionStatus } : TransitionMaskProps): React.ReactElement => {
  const data = useStaticQuery(graphql`
    query PictureLoadingQuery {
      sanitySiteSettings {
        loadingImage {
          asset {
            url
          }
        }
        loadingImageMobile {
          asset {
            url
          }
        }
      }
    }
  `)
  const { sanitySiteSettings: { loadingImage, loadingImageMobile } } = data

  return (
    <Mask
      loadingSrc={imgixPath(loadingImage.asset.url)}
      loadingSrcMobile={imgixPath(loadingImageMobile.asset.url)}
      {...{ transitionStatus }}
      className={transitionStatus === 'entered' ? '' : 'transitioning'}
    />
  )
}


const Mask = styled.div<TransitionMaskProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10000;
  transition: opacity 0.5s 0.1s ease-in-out, visibility 0.01s 0.51s;
  visibility: hidden;
  opacity: 0;
  background-image: url(${props => props.loadingSrcMobile});
  background-position: center center;
  background-repeat: no-repeat;
  background-color: rgb(204, 255, 0);
  @media only screen and (min-width: 768px) {
    background-image: url(${props => props.loadingSrc});
  }
  &.transitioning {
    pointer-effects: none;
    opacity: 1;
    transition: opacity 0.5s 0.1s ease-in-out, visibility 0.01s 0.01s;
    visibility: visible;
  }
`


const WrappedMask = () => {
  return (
    <TransitionState>
      {({ transitionStatus }: TransitionMaskProps) => <TransitionMask {...{ transitionStatus }} /> }
    </TransitionState>
  )
}

export default WrappedMask
