export const palette = {
  white: 'rgb(255, 255, 255)',
  black: 'rgb(0, 0, 0)',
  gray: 'rgb(134, 134, 134)',
  electricLime: 'rgb(204, 255, 0)',
}

export const effect = {
  magnify: `
    @media only screen and (min-width: 920px) {
      transition: transform 0.25s ease-in-out;
      transform-origin: center center;
      transform: scale(1.001);
      &:hover {
        transform: scale(1.025);
      }  
    }
  `,
}