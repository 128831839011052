import React from "react"
import styled from 'styled-components'
import BlockContent from '@sanity/block-content-to-react'
import { palette } from '@theme'


const BlockSerializer = ({children, node}): JSX.Element => {
  if(!children) return <></>
  switch(node.style) {
    case 'h1' :
      return <H1>{children}</H1>
    case 'h2' :
      return <H2>{children}</H2>
    case 'h3' :
      return <H3>{children}</H3>
    default:
      return <Paragraph>{children}</Paragraph>
  }
}

const BlockRenderer = ({children}) => {
  return (
    <StyledBlockContent
      blocks={children}
      serializers={{
        types: {
          block: props => (<BlockSerializer {...props} />),
        },
        marks: {
        },
      }}
    />
  )
}



const StyledBlockContent = styled(props => <BlockContent {...props} />)`
    > p {
      margin-bottom: 1em;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
    ul {
      margin-left: 25px;
    }
    li {
      list-style-type: disc;
    }
    a {
      cursor: url('/cursors/pointer.cur') 6 0, auto;
      cursor: -webkit-image-set(
        url('/cursors/pointer.cur') 1x,
        url('/cursors/pointer_hi.cur') 2x
      ) 6 0, auto;
      display: inline-block;
      margin: 1em auto;
      padding: 2px 10px 4px 10px;
      background-color: rgba(204, 255, 0, 1);
      color: black;
      border-radius: 15px;
      text-transform: uppercase;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;
      &:hover {
        color: rgba(204, 255, 0, 1);
        background-color: black;
      }
    }
`



const H1 = styled.h1`
  font-weight: 300;
  letter-spacing: 0;
  line-height: 1.1em;
  text-transform: uppercase;
  a {
    cursor: url('/cursors/pointer.cur') 6 0, auto;
    cursor: -webkit-image-set(
      url('/cursors/pointer.cur') 1x,
      url('/cursors/pointer_hi.cur') 2x
    ) 6 0, auto;
    display: inline-block;
    margin: 1em auto;
    padding: 2px 10px 4px 10px;
    background-color: rgba(204, 255, 0, 1);
    color: black;
    border-radius: 15px;
    text-transform: uppercase;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;
    &:hover {
      color: rgba(204, 255, 0, 1);
      background-color: black;
    }
  }
`

const H2 = styled.h2`
  font-weight: 300;
  letter-spacing: 0;
  line-height: 1.1em;
  text-transform: uppercase;
  margin-bottom: 1em;
  &:last-child {
    margin-bottom: 0;
  }
`

const H3 = styled.h3`
  font-weight: 300;
  letter-spacing: 0;
  line-height: 1.1em;
  text-transform: uppercase;
`

const Paragraph = styled.p`
  line-height: 1.1em;
  color: ${palette.gray};
`



export default BlockRenderer