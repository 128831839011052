import React from "react"
import { BlockRenderer, LinkMask, Video } from '@components/atoms'
import { GridItemWrapper } from '@components/grid'
import { PictureWrapper, CaptionWrapper } from '@components/grid/GridItemImage'
import { GridItemVideoProps } from '@types'
import { useGridHelper } from '@utils'


const GridItemVideo = ({
  link,
  post,
  children,
  gridItemTypeSwitch,
  className,
  columns, 
  columnStart,
  frameSize,
  paddedBackgroundColor,
  square,
}: GridItemVideoProps): React.ReactElement => {
  const { gridHelperEnabled } = useGridHelper()
  if( !gridItemTypeSwitch.gridItemVideo ) return <></>
  const {
    gridItemVideo: {
      video,
      _rawCaption,
      additionalPadding,
    },
  } = gridItemTypeSwitch
  return (
    <GridItemWrapper {...{ className, columns, columnStart, gridHelperEnabled }}>
      <LinkMask {...{ link, post }} />
      <PictureWrapper {...{ columns, paddedBackgroundColor, additionalPadding, frameSize, square }} className="paddingHighlight" >
        <Video autoPlay muted loop playsInline
          src={video?.asset.url}
          {...{ additionalPadding }}
        />
      </PictureWrapper>
      {_rawCaption && (
        <CaptionWrapper>
          <BlockRenderer>{_rawCaption}</BlockRenderer>
          {children}
        </CaptionWrapper>
      )}
    </GridItemWrapper>
  )
}





export default GridItemVideo