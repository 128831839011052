import React from "react"
import styled from 'styled-components'
import { LinkMask, Picture, Video } from '@components/atoms'
import { PictureWrapper } from '@components/grid/GridItemImage'


export interface SimplePostProps {
  gridItemTypeSwitch: {
    gridItemType: string
    gridItemImage?: {
      image: {
        asset: {
          url: string
          metadata: {
            dimensions: {
              width: number
              height: number
              aspectRatio: number
            }            
          }
        }
      }
    }
    gridItemVideo?: {
      video: {
        asset: {
          url: string
        }
      }
    }
  }
  post: {
    _key: string
    title: string
    year: string
    description: string
    slug: {
      current: string
    }
    paddedBackgroundColor: { 
      hex: string
    }
  }
}


const RelatedPosts = ({nextPost, previousPost}: {nextPost?: SimplePostProps, previousPost?: SimplePostProps}) => {
  if( !previousPost && !nextPost ) return <></>
  return (
    <>
      {previousPost && (
        <RelatedPost post={previousPost} left >
          Previous Project
        </RelatedPost>
      )}
      {nextPost && (
        <RelatedPost post={nextPost} >
          Next Project
        </RelatedPost>
      )}
    </>
  )
}


const Header = styled.h2`
  text-align: center;
  margin-bottom: 0.5em;
  text-transform: uppercase;
`


const RelatedPost = ({
  post,
  children
}: RelatedPostWrapperProps) => (
  <RelatedPostWrapper>
    <LinkMask post={post.post} />
    <Header>{children}</Header>
    <StyledPictureWrapper paddedBackgroundColor={post.post.paddedBackgroundColor} >
      {post.gridItemTypeSwitch.gridItemType === 'image' && (
        <Picture src={post.gridItemTypeSwitch.gridItemImage?.image.asset.url!} alt={post.post.title} dimensions={post.gridItemTypeSwitch.gridItemImage?.image.asset.metadata.dimensions} />
      )}
      {post.gridItemTypeSwitch.gridItemType !== 'image' && (
        <Video src={post.gridItemTypeSwitch.gridItemVideo?.video.asset.url!} autoPlay muted loop playsInline />
      )}
    </StyledPictureWrapper>
    <Title className="paddingHighlight" >{post.post.title}</Title>
    <SubTitle>{post.post.description}</SubTitle>
    <Year>{post.post.year}</Year>
  </RelatedPostWrapper>
)


interface RelatedPostWrapperProps {
  left?: boolean
  post: SimplePostProps
  children: React.ReactNode
}


const RelatedPostWrapper = styled.div`
  position: relative;
  @media only screen and (min-width: 768px) {
    grid-column-end: span 3;
  }
  margin-top: 4em;
`


const StyledPictureWrapper = styled(props => <PictureWrapper {...props} />)`
  margin-bottom: 20px;
  @media only screen and (min-width: 768px) {
    margin-bottom: 35px;
  }
`


const Title = styled.h1`
  @media only screen and (min-width: 768px) {
    margin: 0px 70px;
    display: inline !important;
    box-decoration-break: clone;
  }
  text-transform: uppercase;
`

const SubTitle = styled.div`
  color: rgb(134, 134, 135);
  @media only screen and (min-width: 768px) {
    padding: 0px 70px 35px 70px;
  }
`

const Year = styled.div`
  text-align: right;
  @media only screen and (min-width: 768px) {
    padding-right: 70px;
  }
`




export default RelatedPosts