import React from "react"
import styled from 'styled-components'
import { BlockRenderer, LinkMask } from '@components/atoms'
import { GridItemCaptionProps } from '@types'
import { GridItemWrapper } from '@components/grid'
import { useGridHelper } from '@utils'


const GridItemCaption = ({
  link,
  post,
  children,
  gridItemTypeSwitch,
  className,
  columns, 
  columnStart,
}: GridItemCaptionProps & { className?: string }): React.ReactElement => {
  const { gridHelperEnabled } = useGridHelper()
  if( !gridItemTypeSwitch.gridItemCaption ) return <></>  
  const {
    gridItemCaption: {
      _rawCaption,
      textAlign,
      padding,
    },
  } = gridItemTypeSwitch

  return (
    <GridItemWrapper {...{ className, columns, columnStart, gridHelperEnabled }} >
      <LinkMask {...{link, post}} />
      <ItemCaption {...{ textAlign, padding, }} >
        <BlockRenderer>{_rawCaption}</BlockRenderer> 
      </ItemCaption>
      {children}
    </GridItemWrapper>
  )
}


const ItemCaption = styled.div<{
  textAlign?: 'left' | 'right' | 'center' | 'justify',
  padding?: string
}>`
  position: relative;
  padding: 10px 20px;
  text-align: ${props => props.textAlign || 'left'};
  @media only screen and (min-width: 768px) {
    padding: ${props => props.padding ? props.padding : '70px 70px 70px 70px'};
  }
  h3 {
    font-family: 'HCo Sentinel SSm', serif;
    font-weight: 300;
    font-size: 3rem;
    letter-spacing: 0;
    line-height: 1.1em;
    @media only screen and (min-width: 768px) {
      font-size: 3.8rem;
    }
    @media only screen and (min-width: 1024px) {
      font-size: 5rem;
    }
  }
`



export default GridItemCaption