import React from 'react'
import styled from 'styled-components'
import { useInView } from 'react-intersection-observer'


const imgixPath = (input: string) => input.replace('cdn.sanity.io/files/pbavrd7u/production', '4gm-vid.imgix.net')


const Video = ({
  autoPlay, 
  muted, 
  loop, 
  playsInline, 
  src,
  additionalPadding,
}: VideoProps): React.ReactElement => {
  if(!src) return <></>

  const { ref, inView } = useInView({
    threshold: .15,
    triggerOnce: true
  })
  
  const [visible, setVisible] = React.useState(false)
  React.useEffect(() => {
    if(visible) return
    if(inView) setVisible(true)
  }, [inView])
  
  const [loaded, setLoaded] = React.useState(false)

  const $additionalPadding = !additionalPadding
    ? undefined
    : additionalPadding + `vw 0`

  return (
    <VideoTag
      {...{ autoPlay, muted, loop, playsInline, visible, loaded, ref, $additionalPadding }}
      onLoadedData={() => setLoaded(true)}
    >
      <source src={imgixPath(src)} />
    </VideoTag>
  )
}



interface VideoTagProps { 
  rotation?: number
  hideIfDesktop?: boolean
  hideIfMobile?: boolean
  visible: boolean
  loaded: boolean
  $additionalPadding?: string
}

const VideoTag = styled.video<VideoTagProps>`
  width: 100%;
  max-width: 100%;
  border-radius: 15px;
  @media only screen and (max-width: 767px) {
    ${props => props.hideIfMobile && `display: none;`}
  }
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  @media only screen and (min-width: 768px) {
    border-radius: 10px;
    ${props => props.hideIfDesktop && `display: none;`}
    padding: ${props => props.$additionalPadding ? props.$additionalPadding: 0};
  }
  transition: opacity 0.5s ease-in-out, border-radius 0.25s ease-in-out;
  @media only screen and (min-width: 768px) {
    transition: opacity 0.5s ease-in-out, border-radius 0.25s ease-in-out, transform 0.25s ease-out;
  }
  @media only screen and (max-width: 767px) {
    transform: none !important;
  }
`


type VideoProps = {
  autoPlay?: boolean
  muted?: boolean
  loop?: boolean
  playsInline?: boolean
  src: string
  additionalPadding?: string
}


export default Video