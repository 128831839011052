// usage:   {process.env.GATSBY_SANITY_WATCH_MODE && (<GridHelper />)}

import React from 'react'
import styled from 'styled-components'
import { Grid } from '@components/grid'
import { range } from 'lodash'
import { useGridHelperDispatch, useGridHelper } from './'


const GridHelperSwitch = () => {
  const dispatch = useGridHelperDispatch()
  const { gridHelperEnabled } = useGridHelper()
  if(process.env.NODE_ENV !== 'development') return <></>
  return (
    <Wrapper>
      <Label>
        <input
          type="checkbox" 
          name="gridHelperSwitch" 
          checked={gridHelperEnabled} 
          onChange={() => dispatch({type: 'toggle'})}
        />
        <span>grid helper</span>
      </Label>
      <GridWrapper {...{ gridHelperEnabled }} >
        <StyledGrid className="gridHelper" >
          {range(0, 12).map((_, i) => (
            <GridColumn key={`view_column_${i}`} hideMobile={(i > 5)} >{i + 1}</GridColumn>
          ))}
        </StyledGrid>
      </GridWrapper>
    </Wrapper>
  )
}


const Wrapper = styled.div`
  position: absolute;
  bottom: 5px;
  right: 5px;
`


const Label = styled.label`
  color: white;
  cursor: pointer;
  font-size: 8px;
  span {
    padding-left: 5px;
  }
`


const GridWrapper = styled.div<{ gridHelperEnabled: boolean }>`
  display: ${props => props.gridHelperEnabled ? 'block' : 'none'};
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  outline: 5px solid red;
  box-sizing: border-box;
  pointer-events: none;
`


const StyledGrid = styled(props => <Grid {...props} />)`
  margin-top: 0;
  margin-bottom: 0;
`


const GridColumn = styled.div<{ hideMobile: boolean }>`
  @media only screen and (max-width: 767px) {
    display: ${props => props.hideMobile ? 'none' : 'flex'};
  }
  grid-column: span 1;
  display: flex;
  justify-content: flex-start; 
  flex-direction: column-reverse;
  height: 100vh;
  background-color: rgba(128, 128, 128, 0.1);
  text-align: center;
  font-size: 9px;
  color: blue;
`


export default GridHelperSwitch